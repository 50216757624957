<template>
    <div>
        <div class="row">
            <div class="col-lg-12">
                <div class="panel">
                    <div class="panel-body">
                        <h3 class="panel-title text-capitalize">
                            <inline-svg :src="navbarInfo.icon" class="panel-icon" alt="" />
                            {{ navbarInfo.name }} 
                            <button class="btn bg-main btn-sm" @click="$router.go(-1)" v-if="$route.params.type != 'lists'" style="margin-right:3px;"><i class="fa fa-plus"></i></button>
                            <button class="btn bg-main btn-sm" @click="getLists"><i class="fa fa-refresh"></i></button>
                        </h3>

                        <Notification :data="noti" :key="k" v-for="(noti, k) in notification.service_progress"></Notification>

                        <div class="row">
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <select class="form-control" v-model="search.limit" @change="getLists">
                                        <option :value="rows" v-for="rows in $store.state.limitRowsTable">{{ rows | tableRows }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <input type="text" class="form-control" v-model="search.text" placeholder="Tìm kiếm...">
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <select v-model="search.status" class="form-control" @change="getLists">
                                        <option value="progress">Đang chạy</option>
                                        <option value="done">Hoàn thành</option>
                                        <option value="cancel">Hủy đơn</option>
                                        <option value="all">Tất cả</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="table-responsive">
                            <table class="table table-bordered table-hover" :class="[lists.length > 0 ? 'table-views' : '']">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Trạng thái</th>
                                        <th>Username</th>
                                        <th>Avatar</th>
                                        <th>ID</th>
                                        <th>Dịch vụ</th>
                                        <th>Loại</th>
                                        <th v-if="tabReactions">Cảm xúc</th>
                                        <th>Tổng tiền</th>
                                        <th>Số lượng</th>
                                        <th>Ban đầu</th>
                                        <th>Đã chạy</th>
                                        <th>Hiện tại</th>
                                        <th>Giới tính</th>
                                        <th>Bảo hành</th>
                                        <th>Ghi chú</th>
                                        <th>Thời gian</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(x, i) in filter">
                                        <td>{{ lists.length - i }}</td>
                                        <td v-html="x.status"></td>
                                        <td>
                                        	{{ x.username }}
                                        </td>
                                        <td>
                                        	<img :src="x.thumbnail" @error="default_avatar" class="avatar" alt="">
                                        </td>
                                        <td>
                                        	<a :href="x.link_id" target="_blank">{{ x.action_id }}</a>
                                        </td>
                                        <td>
                                            <img :src="require('@/assets/images/service/' + x.provider + '.png')" class="service" alt="">
                                        </td>
                                        <td v-html="x.type"></td>
                                        <td v-if="tabReactions && x.reactions">
                                            <img :src="require('@/assets/images/reactions/' + x.reactions + '.png')" class="service" alt="">
                                        </td>
                                        <td>{{ x.total_money | numberFormat }}</td>
                                        <td>{{ x.max | numberFormat }}</td>
                                        <td>{{ x.begin | numberFormat }}</td>
                                        <td>{{ x.runCount | numberFormat }}</td>
                                        <td>{{ x.present | numberFormat }}</td>
                                        <td v-html="x.gender"></td>
                                        <td v-html="x.is_warranty"></td>
                                        <td>
                                            <input type="text" :value="x.note" class="form-control note" placeholder="Nothing...">
                                        </td>
                                        <td>{{ x.action_time | timeText }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="text-center" v-if="lists.length === 0">
                            <inline-svg :src="require('@/assets/images/icons/404.svg')" height="120" width="120" />
                            <p class="font-weight-bold">Không tìm thấy danh sách...</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import '@/assets/css/lists.css';

    export default {
        props: ['notification'],
        data () {
            return {
                lists: [],
                search: {
                    limit: 50,
                    status: 'all',
                    type: this.$route.params.type
                },
                navbarInfo: {
                    icon: require('@/assets/images/icons/list.svg'),
                    name: this.$route.params.type == 'lists' ? 'Tất cả tiến trình' : 'Danh Sách ID ' + this.$route.params.type.replaceAll('_', ' ')
                },
                tabReactions: false
            }
        },
        created () {
            this.getLists();
            if (this.search.type.includes('facebook')) {
                if (this.search.type.includes('like') && !this.search.type.includes('like_fanpage')) {
                    this.tabReactions = true;
                }
            }
        },
        computed: {
            filter () {
                if (this.search.text) {
                    return this.lists.filter(x => {
                        return (
                            x.action_id.toLowerCase().includes(this.search.text.toLowerCase()) ||
                            x.username.toLowerCase().includes(this.search.text.toLowerCase()) ||
                            x.status.toLowerCase().includes(this.search.text.toLowerCase()) ||
                            x.note.toLowerCase().includes(this.search.text.toLowerCase()) ||
                            x.gender.toLowerCase().includes(this.search.text.toLowerCase()) ||
                            x.provider.toLowerCase().includes(this.search.text.toLowerCase()) ||
                            x.reactions.toLowerCase().includes(this.search.text.toLowerCase())
                        )
                    });
                }
                return this.lists;
            }
        },
        methods: {
            getLists () {
                this.lists = [];
                this.$http.post('service/lists', this.search).then(res => {
                    this.lists = res.body;
                });
            },
            default_avatar (event) {
                event.target.src = require('@/assets/images/default_avatar.jpg');
            }
        }
    }
</script>